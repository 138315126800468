import './App.css'
import HomePage from "./view/homePage";

function App() {
    return (
        <div className='main'>
            <HomePage/>
        </div>
    )
}

export default App
