// @ts-nocheck
// import {useEffect, useState} from "react";
import styles from './index.module.scss'
import Header from "@/view/homePage/components/Header";
import Banner from "@/view/homePage/components/Banner";
// import WorkCard from "@/view/homePage/components/WorkCard";
// import NavMenu from "@/view/homePage/components/NavMenu";
import Footer from "@/view/homePage/components/Footer";
// import Problems from "@/view/homePage/components/ProblemsBox";
// import TechnicalSupport from "@/view/homePage/components/TechnicalSupport";
import ScrollWall from "@/view/homePage/components/ScrollWall";
// import Services from "@/view/homePage/components/ServicesBox";
// import Testimonials from "@/view/homePage/components/TestimonialsBox";
// import BenefitsBox from "@/view/homePage/components/BenefitsBox";
import Pricing from "@/view/homePage/components/PricingBox";
import FeaturedWork from "@/view/homePage/components/FeaturedWork";
import FAQ from "@/view/homePage/components/FAQBox";
import FooterBanner from "@/view/homePage/components/FooterBanner";
// import {problemsData_1, problemsData_2} from "@/contracts/ProblemsData.ts";
import {scrollWallData_1} from "@/contracts/scrollWallData.ts";

function homePage() {
    // const [isTop, setIsTop] = useState(true)
    // // 当页面在顶部时，显示导航栏，否则不显示
    // const checkScroll = () => {
    //     const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    //     setIsTop(scrollTop === 0)
    // }
    // useEffect(() => {
    //     window.addEventListener('scroll', checkScroll)
    //     return () => {
    //         window.removeEventListener('scroll', checkScroll)
    //     };
    // }, []);

    return (
        <div className={styles.home}>
            <div id='header' className={styles.header}>
                <Header/>
                <Banner/>
                {/* <div id="pricing">
                    <Pricing/>
                </div> */}
                {/*<WorkCard/>*/}
            </div>
            <Footer/>
        </div>
    )
}

export default homePage
