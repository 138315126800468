// @ts-nocheck
import styles from "./index.module.scss";
import Loop from "@/assets/sync.svg";
import RightArrow from "@/assets/right-arrow.svg";
import Marquee from "@/view/homePage/components/Marquee";
import Logo from "@/assets/logo.webp";
function homePage() {
  return (
    <div className={styles.banner}>
      <div className={styles.content}>
      <iframe src="https://www.youtube.com/embed/8ouXWlwIHGM?si=z5arjrzGiZ0pgbb5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <div className={styles.title}>
          CHEEMS
          <br />
          Was a Shiba Inu from Hong Kong
          <br />
          He was called Ball Ball
          <br />
          9 January 2011 – 18 August 2023
          <br />
          For CHEEMS
          <br />
          Create a community to help dogs
          <br />
          CA:
          <br />
          0xd7504b0844694cfc34d7
          <br />
          b89371b23c5c6e061eb1
        </div>
        <div className={styles.desc}></div>
      </div>

      <div className={styles["btn-box"]}>
        <div className={styles.btn}>
          <a
            target="__blank"
            href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xd7504b0844694cfc34d7b89371b23c5c6e061eb1&chain=mainnet"
          >
            <span>Buy</span>
          </a>
          <img src={RightArrow} alt="right-arrow" className={styles.arrow} />
        </div>
        {/*<div className={styles.btn}>*/}
        {/*    <a href="./#problems">*/}
        {/*        <span>Learn More</span>*/}
        {/*    </a>*/}
        {/*</div>*/}
      </div>
      {/* <Marquee /> */}
    </div>
  );
}

export default homePage;
