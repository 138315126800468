import styles from './index.module.scss'
function Header() {
    return (
        <div className={styles.header}>
            <div>CHEEMS</div>
        </div>
    )
}

export default Header
