import styles from './index.module.scss'

function Footer() {
    return (
        <footer className={styles.footer}>
                <div className={styles.designed}>
                {/*    <p className="framer-text framer-styles-preset-1vt1p69">*/}
                {/*        Designed by<a className="framer-text framer-styles-preset-ahyvlr"*/}
                {/*                      href="https://www.themattbio.com/" target="_blank" rel="noopener">Matt Bio</a>*/}
                {/*    </p>*/}
                </div>
                <div className={styles.socials}>
                    <a className={styles.icon} href="https://x.com/erc20_cheems" target="_blank" rel="noopener">
                        <div className={styles.X}/>
                    </a>
                    <a className={styles.icon} href="https://t.me/cheems_erc20_chinese" target="_blank" rel="noopener">
                        <div className={styles.Telegram}/>
                    </a>
                    <a className={styles.icon} href="https://t.me/cheems_erc20_english" target="_blank" rel="noopener">
                        <div className={styles.Telegram}/>
                    </a>
                    <a className={styles.text} href="https://www.dextools.io/app/en/ether/pair-explorer/0xedb5c8f988df58d9d9d62812b0f64b9613753f61?t=1728638859192" target="_blank" rel="noopener">
                        dextools
                    </a>
                    <a className={styles.text} href="https://dexscreener.com/ethereum/0xedb5c8f988df58d9d9d62812b0f64b9613753f61" target="_blank" rel="noopener">
                        dexscreener
                    </a>
                    {/* <a className={styles.icon} href="https://pinterest.com/" target="_blank" rel="noopener">
                        <div className={styles.Pinterest}></div>
                    </a>
                    <a className={styles.icon} href="https://www.linkedin.com/" target="_blank" rel="noopener">
                        <div className={styles.LinkedIn}></div>
                    </a>
                    <a className={styles.icon} href="https://www.tiktok.com/"
                       target="_blank" rel="noopener">
                        <div className={styles.TikTok}></div>
                    </a>
                    <a className={styles.icon} href="https://www.youtube.com/" target="_blank" rel="noopener">
                        <div className={styles.Youtube}></div>
                    </a> */}
                </div>
        </footer>
    )
}

export default Footer
